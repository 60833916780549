import React from "react"

// Components
import Seo from "../components/Seo"
import Components from "../components/Components"

import GlobalStyle from "../assets/styles/GlobalStyles"
import { Normalize } from 'styled-normalize'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie';
import CookiesConsent from "../components/Cookies"


import data from "../data/es.json"

const light = {
  PrimaryColor: "#03a9f4",
  SecundaryColor: "#0286c2",
  TitleColor: "#141414",
  SubtitleColor: "#4a4a4a",
  BgColor: "#fdfdfd",
  BgCard: "#f1f3f4",
  IconColor: "#FFFFFF"
};

const tags = {
  cookie_message: "Usamos cookies propias y de terceros para mejorar tu experiencia como usuario.",
  cookie_accept: "Aceptar"
}

function IndexPage() {
  const browser = typeof window !== "undefined" && window;

  return (<>
    {browser && (<>
      <Seo data={data.seo_info} />
      <Normalize />
      <ThemeProvider theme={light}>
        <GlobalStyle />
        {data.components.map(block => Components(block))}
      <CookiesProvider>
        <CookiesConsent data={tags} />
      </CookiesProvider>
      </ThemeProvider>
    </>)}</>);
}

export default IndexPage
