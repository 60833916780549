import React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import Fade from "react-reveal/Fade"


import Title from "./Title"
import Image from "./Image"

function SplitString(props) {
    let text = props.data.toString();
    let newText = text.split('\\n').map((nstr, key) => <p className="mb-4" key={key} dangerouslySetInnerHTML={{__html: nstr}}></p>);
    return newText;
}

function GridTwo({ data }) {

    return (
        <Container id="gridtwo" className="gridtwo pb-5">
            <Row>
                {data.title ? <Title data={data.title} />:""}
                {data.fileImage !== undefined ?
                    <Col xs="12" md="5" className="text-center mb-4">
                       {data.fileImage.imageURL ? <Fade><Image data={data.fileImage.imageURL} /></Fade>: <div className="bg-secundary"></div>}
                    </Col>
                    : ""}
                {data.description !== undefined ?
                <Col xs="12" md="7">
                    <Fade><SplitString data={data.description} /></Fade>
                </Col>
                 : ""}
            </Row>
        </Container>)
}

export default GridTwo