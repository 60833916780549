import React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import Fade from "react-reveal/Fade"

import Title from "./Title"
import GridTitle from "./GridTitle"
import GridSubtitle from "./GridSubtitle"
import GridImage from "./GridImage"

function GridRow({data}) {

    return (
        <Container id="gridrow" className="pb-5">
            <Row>
                {data.title ? <Title data={data.title} />:""}
                {data.list !== undefined ? data.list.map((key, index) =>
                    <Col key={index} xs={12} md={6} className="mb-3">
                            <Row className="align-items-center">
                            {key.fileImage !== undefined ?
                                <Col xs={12} md={4} className="mb-1">
                                <Fade up><GridImage data={key.fileImage.imageURL}/></Fade>
                                </Col>:""}
                                <Col xs={12} md={8} className="mb-1">
                                   <Fade> <GridTitle>{key.name}</GridTitle></Fade>
                                   <Fade><GridSubtitle>{key.subtitle}</GridSubtitle></Fade>
                                </Col>
                                
                            </Row>
                    </Col>
                ): ""}
            </Row>
        </Container>
    )
}

export default GridRow