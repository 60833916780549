import React from "react"
import styled from "styled-components"

const ImageWrapper = styled.div`
    position: relative;
    display: block;
    overflow: hidden;
    padding-bottom: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
`
const Image = styled.img`
    content-visibility: auto;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
`
function GridImage({ data }) {

    return (
        <ImageWrapper >
            {data ? <Image src={data} alt="grid" />: <div className="bg-secundary"></div>}
            {/* <ImageOvarlay /> */}
        </ImageWrapper>
    )
}
GridImage.defaultProps = {
    data: ""
}
export default GridImage