import React, { Component } from "react"
import CookieIcon from "../assets/images/cookie.svg"
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import styled from "styled-components";

const CookieWrapper = styled.div`
    display: ${props => props.activate};
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    padding: 16px 20px;
    position: fixed;
    bottom: 5%;
    left: 0;
    z-index: 999;
    max-width: 500px;
`
const CookieAccept = styled.span`
  display: inline-block;
  max-width: 350px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #444444;  
`
const CookieText = styled.div`
    font-size: 0.8rem;
    color: #444444;
`

const CookieButton = styled.button`
    display: inline-block;
    vertical-align: top;
    border: none;
    background: #FFFFFF;
`

const CookieIcono = styled.img`
    display: block;
    width: 25px;
    height: 25px;
    margin: 0.5rem auto 0 auto;
    height: 1em;
`


class CookiesConsent extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            accepted: true,
            isServer: true
        };
      }

      handleClick() {
        const { cookies } = this.props;
        cookies.set('isAccepted', true, { path: '/' });
        this.setState({ accepted : true,
                        isServer : true  });
      }

      componentDidMount() {
        const { cookies } = this.props;
        this.setState({
          isServer: false,
          accepted: cookies.get('isAccepted') || false,
        })
      }
    

    render() {
    
        const { accepted, isServer } = this.state    
        const key = isServer ? `client` : `server`

        return (
            <CookieWrapper activate={accepted ? "none": "inline"} key={key}>
              <div className="container">
                <div className="row">
                  <CookieText className="col-9 my-auto">{this.props.data.cookie_message}</CookieText>
                  <div className="col-3 cookies-custom-icon">
                  <CookieButton onClick={this.handleClick}>
                      <CookieIcono src={CookieIcon} alt="cookie icon"/>
                      <CookieAccept>{this.props.data.cookie_accept}</CookieAccept>
                  </CookieButton>
                  </div>
                </div>
              </div>
            </CookieWrapper>

        );
    }
}

export default withCookies(CookiesConsent);
